const getAllRoles = (): string[] => ['Admin', 'TechAdmin', 'ClientAdmin', 'ICAdmin'];
const getTeachAdmin = (): string[] => ['TechAdmin'];;
const getTechAndClientAdmin = (): string[] => ['TechAdmin', 'ClientAdmin'];;
const getICAdmin = (): string[] => ['ICAdmin'];

export {
  getAllRoles,
  getTeachAdmin,
  getTechAndClientAdmin,
  getICAdmin
}

export const DynamicAsideMenuConfig = {
  items: [
    {
      title: 'Insurance Companies Logs',
      root: true,
      iconClass: 'fas fa-car-crash',
      page: '/insurance-companies',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin', 'ICAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Najm & Elm Logs',
      root: true,
      iconClass: 'fas fa-address-card',
      page: '/najm-elm',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin', , 'ICAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Failed Policy Logs',
      root: true,
      iconClass: 'fas fa-address-card',
      page: '/fail-policy/FailedPolicy',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin', , 'ICAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Policies Report',
      root: true,
      iconClass: 'fas fa-address-card',
      page: '/policies-report',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin', , 'ICAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Users Report',
      root: true,
      iconClass: 'fas fa-address-card',
      page: '/users-report',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin', , 'ICAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Delete NIN',
      root: true,
      iconClass: 'fas fa-trash',
      page: '/delete-nin',
      permissions: ['TechAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Payment Logs',
      root: true,
      iconClass: 'fas fa-dollar-sign',
      page: '/hyper-pay/logs',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin'],
      bullet: 'dot',
    },
    {
      title: 'Finance Reports',
      root: true,
      iconClass: 'fas fa-wallet',
      page: '/finance-report',
      permissions: ['Admin', 'TechAdmin', 'ClientAdmin', 'ICAdmin'],
      bullet: 'dot',
    },
    {
      title: 'User Checkout Info',
      root: true,
      iconClass: 'fas fa-dollar-sign',
      page: '/user-checkout',
      permissions: getAllRoles(),
      bullet: 'dot',
    },
    {
      title: 'Update Company Status',
      root: true,
      iconClass: 'fas fa-edit',
      page: 'company-settings/update-company-status',
      permissions: getTechAndClientAdmin(),
      bullet: 'dot',
    },
    {
      title: 'Add Admin',
      root: true,
      iconClass: 'fas fa-recycle',
      page: '/add-admin/admin',
      permissions: getTechAndClientAdmin(),
      bullet: 'dot',
    },
    {
      title: 'Policies Statistics',
      root: true,
      iconClass: 'fa fa-chart-line',
      page: '/policies-statistics',
      permissions: getTechAndClientAdmin(),
      bullet: 'dot',
    }
  ],
};




