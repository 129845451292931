// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v711demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  apiUrl: 'api',
  local: {
    "production": false,
    "serverUrl": "https://localhost:62710/api/",
    "insurerQuoteServiceUrl": "https://localhost:44319/api/motor-insurance/",
    "baseUrl": "https://localhost:44319/",
    "identityUrl": "https://localhost:44331",
    "dummyClientSecret": "km_JeQ8W50",
    "clientId": "Admin"
  },
  dev: {
    "production": true,
    "serverUrl": "https://api-dev.eltizam.sa/apigateway/api/BusinessLog/",
    "insurerQuoteServiceUrl": "https://api-dev.eltizam.sa/apigateway/api/motor-insurance/",
    "baseUrl": "https://api-dev.eltizam.sa/apigateway/",
    "identityUrl": "https://api-dev.eltizam.sa/identity",
    "dummyClientSecret": "km_JeQ8W50",
    "clientId": "Admin"
  },
  qc: {
    "production": true,
    "serverUrl": "https://api-qc.eltizam.sa/apigateway/api/BusinessLog/",
    "insurerQuoteServiceUrl": "https://api-qc.eltizam.sa/apigateway/api/motor-insurance/",
    "baseUrl": "https://api-qc.eltizam.sa/apigateway/",
    "identityUrl": "https://api-qc.eltizam.sa/identity",
    "dummyClientSecret": "km_JeQ8W50",
    "clientId": "Admin"
  },
  uat: {
    "production": true,
    "serverUrl": "https://api-uat.eltizam.sa/apigateway/api/BusinessLog/",
    "insurerQuoteServiceUrl": "https://api-uat.eltizam.sa/apigateway/api/motor-insurance/",
    "baseUrl": "https://api-uat.eltizam.sa/apigateway/",
    "identityUrl": "https://api-uat.eltizam.sa/identity",
    "dummyClientSecret": "km_JeQ8W50",
    "clientId": "Admin"
  },
    prod: {
        "production": true,
        "serverUrl": "https://api.eltizam.sa/apigateway/api/BusinessLog/",
        "insurerQuoteServiceUrl": "https://api.eltizam.sa/apigateway/api/motor-insurance/",
        "baseUrl": "https://api.eltizam.sa/apigateway/",
        "identityUrl": "https://api.eltizam.sa/identity",
        "dummyClientSecret": "km_JeQ8W50",
        "clientId": "Admin"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
