import { AddAdminService } from 'src/app/core/services/add-admin.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordGuard implements CanActivate, CanActivateChild {

  constructor(private adminService: AddAdminService,
    private router: Router,
    private loaderService: NgxUiLoaderService) {

  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.adminService.checkMustChangePassword()
      .toPromise()
      .then((x) => {
        console.log(x);
        if (x.data.mustChangePassword == true) {
          this.router.navigate(['admin/confirm-password'])
          this.loaderService.stopAll();
          return false;
        }
      });
    return true;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    this.adminService.checkMustChangePassword()
      .toPromise()
      .then((x) => {
        console.log(x);
        if (x.data.mustChangePassword == true) {
          this.router.navigate(['admin/confirm-password'])
          this.loaderService.stopAll();
          return false;
        }
      });
    return true;
  }

}
