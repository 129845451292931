import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { TranslationService } from './core/i18n/translation.service';
// language list
import { locale as enLang } from './core/i18n/vocabs/en';
import { SplashScreenService } from './partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    constructor(
        private translationService: TranslationService,
        private splashScreenService: SplashScreenService,
        private router: Router,
        private authService: AuthService
    ) {
        console.log("----9");

        authService.runInitialLoginSequence();
        // register translations
        this.translationService.loadTranslations(enLang);
    }

    ngOnInit() {


        console.log("----13");

        // this.authService.runInitialLoginSequence();
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
