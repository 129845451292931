import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DynamicAsideMenuConfig } from '../../configs/layout/dynamic-aside-menu.config';
import { PermissionsManager } from '../../managers/permissions.manager';
import { AuthService } from '../auth.service';

const emptyMenuConfig = {
    items: []
};

@Injectable({
    providedIn: 'root'
})
export class AsideMenuService {
    private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
    menuConfig$: Observable<any>;

    constructor(private permissionsManager: PermissionsManager, private authService: AuthService) {
        this.menuConfig$ = this.menuConfigSubject.asObservable();
        this.loadMenu();
    }

    // Here you able to load your menu from server/data-base/localStorage
    // Default => from DynamicAsideMenuConfig
    private loadMenu() {
        const claims = this.authService.claims;
        const items = [];

        if (String(claims.email).toLocaleLowerCase() === 'falekrsh@SAMA.GOV.SA'.toLowerCase()) {
            const item = DynamicAsideMenuConfig.items[0];
            items.push(item);
        } else {
            DynamicAsideMenuConfig.items.forEach(menuItem => {

                if (menuItem.hasOwnProperty('permissions')) {
                    let showItem = false;
                    menuItem.permissions.forEach(permission => {
                        if (this.permissionsManager.isPermissionGranted(permission)) {
                            showItem = true;
                        }
                    });
                    if (showItem) {
                        items.push(menuItem);
                    }
                }
            });
        }


        this.setMenu({ items });
    }

    private setMenu(menuConfig) {
        this.menuConfigSubject.next(menuConfig);
    }

    private getMenu(): any {
        return this.menuConfigSubject.value;
    }
}
