import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../configs/app.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddAdminService {

  constructor(private http: HttpClient, private config: AppConfigService) { }

  addNewAdmin(data: any) {
    return this.http.post<any>(this.config.getConfig().baseUrl + 'identity/api/Account/Admin/register', data)
  }

  createPassword(sendPass: any) {
    return this.http.post<any>(this.config.getConfig().baseUrl + 'identity/api/Account/Admin/changepassword', sendPass);
  }

  checkMustChangePassword() {
    return this.http.get<any>(this.config.getConfig().baseUrl + 'identity/api/Account/Admin/mustchangepassword');
  }

}
